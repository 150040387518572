import "./datatable.scss";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { userColumns, userRows } from "../../datatablesource";

import Plus from "../../assets/Plus.png"
import Cross from "../../assets/cross.png"
import Filter from "../../assets/Filter.png"
import Export from "../../assets/Export.png"
import SIcon from "../../assets/Sicon.svg"


const Datatable = () => {

  return (

     <div className="datatable">
      <div className="datatableTitle">
        My People    
        <Link to="/users/new" style={{textDecoration:"none"}}>
        <button   className="btn"><img src={Plus} alt="plus" style={{height:"18px",width:"18px",color:"1c1c1c"}} /><span style={{fontStyle:"bold"}}>Add People</span></button>
        </Link>
      </div>
      <div className='container'>
      <div className='wrapper'>
      <div className='left'>     
<button   className="custombtn">Type<img src={Cross} style={{height:"12px",width:"12px"}} alt="close" /></button>
<button   className="custombtn">City<img src={Cross} style={{height:"12px",width:"12px"}} alt="close"/></button>
<button   className="custombtn">Name<img src={Cross} style={{height:"12px",width:"12px"}} alt="close" /></button>
<button   className="nullbutton"><img src={Filter} style={{height:"12px",width:"12px"}}  alt="filter"/>Filter</button>
<button   className="nullbutton" style={{color:"#999999"}}><img src={Export}alt="export" style={{height:"12px",width:"12px" }} />Export</button>
       </div>
      <div className='center'>        
 </div>
      <div className="rightside">
        <div className="search">
          <img src={SIcon} alt="search"/>
          <input placeholder="Search" />
        </div>
      </div>
      </div>
      </div>
      <DataGrid className="tableHeader"
        rows={userRows}
        columns={userColumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
     </div>
  );
}

export default Datatable;