import "./lfdatatable.scss";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { userColumns, userRows } from "../../liveflowsources";

import Plus from "../../assets/Plus.png"
import Cross from "../../assets/cross.png"
import Filter from "../../assets/Filter.png"
import Export from "../../assets/Export.png"
import SIcon from "../../assets/Sicon.svg"

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const Lfdatatable = () => {
 
  return (

     <div className="datatable">
      <div className="datatableTitle">
    <div className="container">
        <div className="wrapper">
        <div className="left">
       Live Flow   
        </div>
        <div className="center">
    <button className="arrowbtn"><KeyboardArrowLeftIcon/></button>   
      <div className="Ctxt">
        <span className="thisweek">This Week</span>
        <span className="date">June 06-12</span>
        </div>
      <button className="arrowbtn"><KeyboardArrowRightIcon/></button>   

        </div>
        <div className="rightside"> 
        <Link to="/users/new" style={{textDecoration:"none"}}>
        <button className="btn"><img src={Plus} alt="plus" style={{height:"18px",width:"18px",color:"1c1c1c"}} />Add Log Flow</button>
        </Link>
        </div>
        </div>
        </div>
      </div>
    
      <DataGrid className="tableHeader"
        rows={userRows}
        columns={userColumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
     </div>
  );
}

export default Lfdatatable;