import React from 'react'
import {motion} from 'framer-motion'
import './item.css'

function Item({icon, name}) {

    // const subheading ={
    //     true:{
    //         opacity: 1
    //     },
    //     false:{
    //         opacity: 0,
    //         display: 'none'
    //     }
    // }
  return (
    <motion.div className='item'
    whileHover = {{
        backgroundColor: "rgba(255, 199, 0, 1)",
        boxShadow: "0px 8px 16px rgba(164, 128, 3, 0.3)",
        backdropFilter: "blur(5.5px)",
        WebkitBackdropFilter: "blur(5.5px)",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        cursor: 'pointer'
    }}
    transition={{
        type:'none', duration: 0.1
    }}
    >
        <motion.div className='icon'>
            {icon}
        </motion.div>
        <motion.span
       className='menutxt'
        >
            {name}
        </motion.span>
    </motion.div>
  )
}

export default Item