import React from 'react'
import "./testing.scss"

const Testing = () => {
  return (
    <div className="chkcontainer">
    <span className="switch">
      <input type="checkbox" id="switcher" />
      <label for="switcher"></label>
    </span>
</div>  
 )
}

export default Testing