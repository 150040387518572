export const userColumns = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "type", headerName: "Type", width: 50,
  renderCell: (params) => {
    return (
      <div className={`typecell ${params.row.type}`}>
        {params.row.type}
      </div>
    );
  },
},

  {
    field: "user",
    headerName: "Name",
    width: 250,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "address",
    headerName: "Street Address",
    width: 200,
  },

  {
    field: "city",
    headerName: "City",
    width: 140,
  },
  {
    field: "code",
    headerName: "Zip Code",
    width: 100,
  },
  {
    field: "phone",
    headerName: "Phone Number",
    width: 140,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      );
    },
  },
];

//temporary data
export const userRows = [
  {
    id: 1,
    type:"A",
    username: "Mark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "None",
    address: "1314 Robison Dr.",
    city: "North Tustin",
    code:"9278",
    phone:"(714)732-5644",
  },
  {
    id: 2,
    type:"B",
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "16664 Rainier Dr.",
    status: "Under Contract",
    city: "Santa Ana",
    code:"1119",
    phone:"(714)732-5644",
  },
  {
    id: 3,
    type:"C",
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "1732 Brittany Woods Dr.",
    status: "Control",
    city: "Fullerton",
    code:"92780",
    phone:"(714)732-5644",
  },
  {
    id: 4,
    type:"A",
    username: "Stark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "3335 Watermarke Place",
    status: "Hot",
    city: "Denver",
    code:"2327",
    phone:"(714)732-5644",
  },
  {
    id: 5,
    type:"B",
    username: "Targaryen",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "16664 Rainier Dr.",
    status: "Hot",
    city: "North Tustin",
    code:"9278",
    phone:"(714)732-5644",
  },
  {
    id: 6,
    type:"A",
    username: "Melisandre",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "3335 Watermarke Place",
    status: "Hot",
    city: "North Tustin",
    code:"97653",
    phone:"(714)732-5644",
  },
  {
    id: 7,
    type:"A",
    username: "Clifford",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "16664 Rainier Dr.",
    status: "Hot",
    city: "North Tustin",
    code:"1864",
    phone:"(714)732-5644",
  },
  {
    id: 8,
    type:"B",
    username: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "13111 Blueberry",
    status: "Hot",
    city: "North Tustin",
    code:"1220",
    phone:"(714)732-5644",
  },
  {
    id: 9,
    type:"B",
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "5111 Triumphal Dr.",
    status: "Hot",
    city: "Santa Ana",
    code:"9278",
    phone:"(714)732-5644",
  },
  {
    id: 10,
    type:"C",
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "13111 Blueberry",
    status: "Warn",
    city: "Irvin",
    code:"9278",
    phone:"(714)732-5644",
  },
 {
    id: 11,
    type:"B",
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "16664 Rainier Dr.",
    status: "Under Contract",
    city: "Santa Ana",
    code:"1119",
    phone:"(714)732-5644",
  },
  {
    id: 12,
    type:"C",
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "1732 Brittany Woods Dr.",
    status: "Control",
    city: "Fullerton",
    code:"92780",
    phone:"(714)732-5644",
  },
  {
    id: 13,
    type:"B",
    username: "Targaryen",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "13111 Blueberry",
    status: "Hot",
    city: "North Tustin",
    code:"9278",
    phone:"(714)732-5644",
  },
  {
    id: 14,
    type:"A",
    username: "Melisandre",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "5111 Triumphal Dr.",
    status: "Hot",
    city: "North Tustin",
    code:"97653",
    phone:"(714)732-5644",
  },
  {
    id: 15,
    type:"A",
    username: "Clifford",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "1314 Robison Dr.",
    status: "Hot",
    city: "North Tustin",
    code:"1864",
    phone:"(714)732-5644",
  },
  {
    id: 16,
    type:"B",
    username: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "16664 Rainier Dr.",
    status: "Hot",
    city: "North Tustin",
    code:"1220",
    phone:"(714)732-5644",
  },
  {
    id: 17,
    type:"A",
    username: "Mark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "None",
    address: "1314 Robison Dr.",
    city: "North Tustin",
    code:"9278",
    phone:"(714)732-5644",
  },
  {
    id: 18,
    type:"B",
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    address: "16664 Rainier Dr.",
    status: "Under Contract",
    city: "Santa Ana",
    code:"1119",
    phone:"(714)732-5644",
  },
];
