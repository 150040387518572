import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
export const userColumns = [
    {
        field: "person",
        headerName: "Person",
        width: 160,
      },
    {
      field: "date",
      headerName: "Date & Time",
      width: 220,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <CalendarTodayIcon style={{color:"#999999"}} className="cellImg" />
            {params.row.date}
          </div>
        );
      },
    },
    {
      field: "method",
      headerName: "Method",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            <PhoneInTalkOutlinedIcon style={{color:"#D4A600"}} className="cellwithmethod" />
          </div>
        );
      },
    },
  
    {
      field: "conversation",
      headerName: "Conversation Details",
      width: 450,
    },

    {
      field: "required",
      headerName: "F/U Required",
      width: 160,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.required}`}>
            {params.row.required}
          </div>
        );
      },
    },
  ];
  
  //temporary data
  export const userRows = [
    {
      id: 1,
      type:"A",
      person: "Mark",
      date:"Jan 22,2023",
      required: "Completed",
      address: "1314 Robison Dr.",
      conversation: "We talked about their baby now being able to walk.",
      code:"9278",
      phone:"(714)732-5644",
    },
    {
      id: 2,
      type:"B",
      person: "Jamie Lannister",
      date:"Jan 23,2023",
      address: "16664 Rainier Dr.",
      required: "No",
      conversation: "They are trying for a baby.",
      code:"1119",
      phone:"(714)732-5644",
    },
    {
      id: 3,
      type:"C",
      person: "Lannister",
      date:"Jan 24,2023",
      address: "1732 Brittany Woods Dr.",
      required: "Yes",
      conversation: "We are temporarily taking her house off the market.",
      code:"92780",
      phone:"(714)732-5644",
    },
    {
      id: 4,
      type:"A",
      person: "Stark",
      date:"Jan 25,2023",
      address: "3335 Watermarke Place",
      required: "No",
      conversation: "Tyler’s son, Rob just got into college (Stanford)",
      code:"2327",
      phone:"(714)732-5644",
    },
    {
      id: 5,
      type:"B",
      person: "Targaryen",
      date:"Jan 26,2023",
      address: "",
      required: "Completed",
      conversation: "Ran into her at Albertsons. She was preparing for a party at the house...",
      code:"9278",
      phone:"(714)732-5644",
    },
    {
      id: 6,
      type:"A",
      person: "Melisandre",
      date:"Jan 27,2023",
      required: "Yes",
      conversation: "Brittany and I had a conversation over email over their buying requirements.",
      code:"97653",
      phone:"(714)732-5644",
    },
    {
      id: 7,
      type:"A",
      person: "Clifford",
      date:"Jan 28,2023",
      address: "3snow@gmail.com",
      required: "No",
      conversation: "We talked about their baby and hhow ishe is doing after her surgery",
      code:"1864",
      phone:"(714)732-5644",
    },
    {
      id: 8,
      type:"B",
      person: "Frances",
      date:"Jan 29,2023",
      required: "Yes",
      conversation: "I brought over their favorite bottle of wine for a thank you for the invite.",
      city: "North Tustin",
      code:"1220",
      phone:"(714)732-5644",
    },
    {
      id: 9,
      type:"B",
      person: "Roxie",
      date:"Jan 30,2023",
      address: "5111 Triumphal Dr.",
      required: "No",
      conversation: "We are temporarily taking her house off the market.",
      code:"9278",
      phone:"(714)732-5644",
    },
    {
      id: 10,
      type:"C",
      person: "Roxie",
      date:"Jan 31,2023",
      address: "13111 Blueberry",
      required: "Completed",
      conversation: "Cassandra’s son, Rob just got into college (Stanford)",
      code:"9278",
      phone:"(714)732-5644",
    },
   {
      id: 11,
      type:"B",
      person: "Jamie Lannister",
      date:"Feb 1,2023",
      address: "16664 Rainier Dr.",
      required: "Yes",
      conversation: "Ran into her at Albertsons. She was preparing for a party at the house and ....",
      code:"1119",
      phone:"(714)732-5644",
    },
    {
      id: 12,
      type:"C",
      person: "Lannister",
      date:"Feb 2,2023",
      address: "1732 Brittany Woods Dr.",
      required: "No",
      conversation: "We talked about their baby now being able to walk.",
      code:"92780",
      phone:"(714)732-5644",
    },
    {
      id: 13,
      type:"B",
      person: "Targaryen",
      date:"Feb 3,2023",
      address: "3snow@gmail.com",
      required: "Yes",
      conversation: "They are trying for a baby.",
      code:"9278",
      phone:"(714)732-5644",
    },
    {
      id: 14,
      type:"A",
      person: "Melisandre",
      date:"Feb 4,2023",
      address: "",
      required: "No",
      conversation: "Brittany and I had a conversation over email over their buying requirements.",
      code:"97653",
      phone:"(714)732-5644",
    },
    {
      id: 15,
      type:"A",
      person: "Clifford",
      date:"Jan 29,2023",
      address: "",
      required: "No",
      conversation: "We talked about their baby and how he is doing after her surgery",
      code:"1864",
      phone:"(714)732-5644",
    },
    {
      id: 16,
      type:"B",
      person: "Frances",
      date:"Jan 29,2023",
      address: "",
      required: "No",
      conversation: "We talked about their baby and how he is doing after her surgery",

      city: "North Tustin",
      code:"1220",
      phone:"(714)732-5644",
    },
    {
      id: 17,
      type:"A",
      person: "Mark",
      date:"Jan 29,2023",
      required: "Completed",
      conversation: "We talked about their baby and how he is doing after her surgery",
      address: "1314 Robison Dr.",
      city: "North Tustin",
      code:"9278",
      phone:"(714)732-5644",
    },
    {
      id: 18,
      type:"B",
      person: "Jamie Lannister",
      date:"Jan 29,2023",
      conversation: "Brittany and I had a conversation over email over their buying requirements.",
      required: "Yes",
      city: "Santa Ana",
      code:"1119",
      phone:"(714)732-5644",
    },
  ];
  