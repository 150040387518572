import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import Vector from "../../assets/Vector.png"
import SIcon from "../../assets/Sicon.svg"
import Palm from "../../assets/Palm.png"
import Avater from "../../assets/ByeWind.png"
import Arrow from "../../assets/arrow.png"
const Navbar = () => {

  return (
    <div className="navbar">
      <div className="wrapper">
        <img src={Vector}/>
        <div className="search">
        <img src={SIcon}  />
          <input type="text" placeholder="Search" />
        </div>
        <div className="items">
          <div className="item">
            <img src={Palm} style={{width: "180px" , height: "80px"}} />
          </div>
          {/* <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div> */}
          {/* <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div> */}
          {/* <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}
          <div className="item">
            <img
              src={Avater}
              alt=""
              className="avatar"
            />
            <span style={{fontSize:"14px", color:"#1C1C1C", marginLeft:"10px"}}>Joseph Chiavatti</span>
            <img src={Arrow}style={{height:"8px" , width:"11px" , marginLeft:"10px"}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
