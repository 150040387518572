import "./dashdata.scss";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { userColumns, userRows } from "../../liveflowsources";
import { useState } from "react";
import Plus from "../../assets/Plus.png"
import Cross from "../../assets/cross.png"
import Filter from "../../assets/Filter.png"
import Export from "../../assets/Export.png"
import SIcon from "../../assets/Sicon.svg"
import Userplus from "../../assets/userplus.svg"
import PieChart from "../../assets/PieChart.png"
import Ellipse from "../../assets/Ellipse.png"
import Calendar from '@mui/icons-material/CalendarMonth';


import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Testing from "../../pages/test/Testing";


const Dashdata = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isSChecked, setIssChecked] = useState(false);
  const [isTChecked, setIstChecked] = useState(false);
  const [isFChecked, setIsfChecked] = useState(false);
  const [isFIChecked, setIsfiChecked] = useState(false);
  const [isSIChecked, setIssiChecked] = useState(false);
  const [isSEChecked, setIsseChecked] = useState(false);
  const [isEIChecked, setIseiChecked] = useState(false);
  const [isNIChecked, setIsniChecked] = useState(false);
  const [isTEChecked, setIsteChecked] = useState(false);
  const [isELChecked, setIselChecked] = useState(false);
  const [isTWChecked, setIstwChecked] = useState(false);
  const [isTHChecked, setIsthChecked] = useState(false);
  const [isFOChecked, setIsfoChecked] = useState(false);
  const [isFIFChecked, setIsfifChecked] = useState(false);
  const [isSIXChecked, setIssixChecked] = useState(false);
  const [isSEVChecked, setIssevChecked] = useState(false);
  const [isEIGChecked, setIseigChecked] = useState(false);
  const [isNINChecked, setIsninChecked] = useState(false);

  return (

    <div className="datatable">
      <div className="datatableTitle">
        <div className="container">
          <div className="title">
            Dashboard
          </div>
          <div className="wrapper">
            <div className="left">
              <div className="Card">
                <div className="Frame">
                  <span className="frametxt">My Morning Routine</span>
                  <div className="centerbody">
                    <button className="arrowbtn"><KeyboardArrowLeftIcon /></button>
                    <div className="Ctxt">
                      <span className="thisweek">This Week</span>
                      <span className="date">June 06-12</span>
                    </div>
                    <button className="arrowbtn"><KeyboardArrowRightIcon /></button>
                  </div>
                  <div className="calendarrow">
                    <div className="Calendar" >
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIsChecked(!isChecked);
                          }}
                        />
                        <svg
                          className={`checkbox ${isChecked ? "checkbox--active" : ""}`}
                          // This element is purely decorative so
                          // we hide it for screen readers
                          aria-hidden="true"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M1 4.5L5 9L14 1"
                            strokeWidth="2"
                            stroke="#fff"

                          />
                        </svg>
                        <span className="text"> Monday</span>
                      </label>
                    </div>
                    <div className="Calendar" >
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIssChecked(!isSChecked);
                          }}
                        />
                        <svg
                          className={`checkbox ${isSChecked ? "checkbox--active" : ""}`}
                          // This element is purely decorative so
                          // we hide it for screen readers
                          aria-hidden="true"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M1 4.5L5 9L14 1"
                            strokeWidth="2"
                            stroke="#fff"

                          />
                        </svg>
                        <span className="text"> Tuesday</span>
                      </label>


                    </div>
                    <div className="Calendar" >
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIstChecked(!isTChecked);
                          }}
                        />
                        <svg
                          className={`checkbox ${isTChecked ? "checkbox--active" : ""}`}
                          // This element is purely decorative so
                          // we hide it for screen readers
                          aria-hidden="true"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M1 4.5L5 9L14 1"
                            strokeWidth="2"
                            stroke="#fff"

                          />
                        </svg>
                        <span className="text" style={{ marginRight: "15px" }}> Wednesday</span>
                      </label>


                    </div>
                    <div className="Calendar" >
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIsfChecked(!isFChecked);
                          }}
                        />
                        <svg
                          className={`checkbox ${isFChecked ? "checkbox--active" : ""}`}
                          // This element is purely decorative so
                          // we hide it for screen readers
                          aria-hidden="true"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M1 4.5L5 9L14 1"
                            strokeWidth="2"
                            stroke="#fff"

                          />
                        </svg>
                        <span className="text"> Thursday</span>
                      </label>


                    </div>
                    <div className="Calendar" >
                      <label>
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIsfiChecked(!isFIChecked);
                          }}
                        />
                        <svg
                          className={`checkbox ${isFIChecked ? "checkbox--active" : ""}`}
                          // This element is purely decorative so
                          // we hide it for screen readers
                          aria-hidden="true"
                          viewBox="0 0 15 11"
                          fill="none"
                        >
                          <path
                            d="M1 4.5L5 9L14 1"
                            strokeWidth="2"
                            stroke="#fff"

                          />
                        </svg>
                        <span className="text" style={{ marginLeft: "5px" }}> Friday</span>
                      </label>


                    </div>
                  </div>

                </div>
                <div className="secondFrame">

                  <div className="gratidudediv">
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIssiChecked(!isSIChecked);
                        }}
                      />
                      <svg
                        className={`scheckbox ${isSIChecked ? "scheckbox--active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>

                    </label>
                    <span className="stxt" style={{ marginTop: "2px" }}>Gratitude</span>

                  </div>
                  <div className="inputtxt">
                    <label>
                      <input style={{ marginLeft: "55px" }}
                        placeholder="write your goal" />
                      <svg style={{ marginLeft: "220px" }}
                        width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.0004 14.4082H30.0004C30.5527 14.4082 31.0004 13.9605 31.0004 13.4082C31.0004 12.8559 30.5527 12.4082 30.0004 12.4082H24.0004C23.4481 12.4082 23.0004 12.8559 23.0004 13.4082C23.0004 13.9605 23.4481 14.4082 24.0004 14.4082Z" fill="#1C1C1C" />
                        <path d="M26.0004 10.4082V16.4082C26.0004 16.9605 26.4481 17.4082 27.0004 17.4082C27.5527 17.4082 28.0004 16.9605 28.0004 16.4082V10.4082C28.0004 9.85592 27.5527 9.4082 27.0004 9.4082C26.4481 9.4082 26.0004 9.85592 26.0004 10.4082Z" fill="#1C1C1C" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5004 0.408203C12.5004 0.408203 14.229 0.408203 15.8093 1.07659C15.8093 1.07659 17.3348 1.72185 18.5108 2.8978C18.5108 2.8978 19.6867 4.07374 20.332 5.59931C20.332 5.59931 21.0004 7.17955 21.0004 8.9082C21.0004 8.9082 21.0004 10.6369 20.332 12.2171C20.332 12.2171 19.6867 13.7427 18.5108 14.9186C18.5108 14.9186 17.3348 16.0946 15.8093 16.7398C15.8093 16.7398 14.229 17.4082 12.5004 17.4082C12.5004 17.4082 10.7717 17.4082 9.19148 16.7398C9.19148 16.7398 7.66591 16.0946 6.48996 14.9186C6.48996 14.9186 5.31401 13.7427 4.66875 12.2171C4.66875 12.2171 4.00037 10.6369 4.00037 8.9082C4.00037 8.9082 4.00037 7.17955 4.66875 5.59931C4.66875 5.59931 5.31401 4.07374 6.48996 2.8978C6.48996 2.8978 7.66591 1.72185 9.19148 1.07659C9.19148 1.07659 10.7717 0.408203 12.5004 0.408203ZM12.5004 2.4082C12.5004 2.4082 9.80798 2.4082 7.90417 4.31201C7.90417 4.31201 6.00037 6.21582 6.00037 8.9082C6.00037 8.9082 6.00037 11.6006 7.90417 13.5044C7.90417 13.5044 9.80798 15.4082 12.5004 15.4082C12.5004 15.4082 15.1928 15.4082 17.0966 13.5044C17.0966 13.5044 19.0004 11.6006 19.0004 8.9082C19.0004 8.9082 19.0004 6.21582 17.0966 4.31201C17.0966 4.31201 15.1928 2.4082 12.5004 2.4082Z" fill="#1C1C1C" />
                        <path d="M17.9947 18.6249C20.6067 19.8429 22.4591 22.0507 22.4591 22.0507C22.6493 22.2774 22.9298 22.4082 23.2254 22.4082L23.2415 22.408C23.4711 22.4043 23.6923 22.3218 23.8681 22.1742C24.0946 21.9842 24.2254 21.7038 24.2254 21.4082L24.2253 21.392C24.2216 21.1625 24.139 20.9412 23.9915 20.7654L23.9906 20.7643C21.8533 18.2175 18.84 16.8123 18.84 16.8123C15.826 15.4067 12.5004 15.4067 12.5004 15.4067C9.1748 15.4067 6.16083 16.8123 6.16083 16.8123C3.14747 18.2175 1.01019 20.7644 1.01019 20.7644L1.00932 20.7654C0.858215 20.9455 0.775391 21.1731 0.775391 21.4082L0.775395 21.4111C0.775478 21.4392 0.776747 21.4673 0.7792 21.4954C0.802326 21.7596 0.92946 22.0038 1.13264 22.1742C1.31273 22.3253 1.5403 22.4082 1.77539 22.4082C1.8045 22.4082 1.83359 22.4069 1.86259 22.4044C2.1268 22.3812 2.37099 22.2541 2.54146 22.0509C4.39399 19.843 7.00611 18.6249 7.00611 18.6249C9.61822 17.4067 12.5004 17.4067 12.5004 17.4067C15.3826 17.4067 17.9947 18.6249 17.9947 18.6249Z" fill="#1C1C1C" />
                      </svg>

                    </label>
                    <label>
                      <input style={{ marginLeft: "55px" }}
                        placeholder="write your goal" />
                      <svg style={{ marginLeft: "220px" }}
                        width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.0004 14.4082H30.0004C30.5527 14.4082 31.0004 13.9605 31.0004 13.4082C31.0004 12.8559 30.5527 12.4082 30.0004 12.4082H24.0004C23.4481 12.4082 23.0004 12.8559 23.0004 13.4082C23.0004 13.9605 23.4481 14.4082 24.0004 14.4082Z" fill="#1C1C1C" />
                        <path d="M26.0004 10.4082V16.4082C26.0004 16.9605 26.4481 17.4082 27.0004 17.4082C27.5527 17.4082 28.0004 16.9605 28.0004 16.4082V10.4082C28.0004 9.85592 27.5527 9.4082 27.0004 9.4082C26.4481 9.4082 26.0004 9.85592 26.0004 10.4082Z" fill="#1C1C1C" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5004 0.408203C12.5004 0.408203 14.229 0.408203 15.8093 1.07659C15.8093 1.07659 17.3348 1.72185 18.5108 2.8978C18.5108 2.8978 19.6867 4.07374 20.332 5.59931C20.332 5.59931 21.0004 7.17955 21.0004 8.9082C21.0004 8.9082 21.0004 10.6369 20.332 12.2171C20.332 12.2171 19.6867 13.7427 18.5108 14.9186C18.5108 14.9186 17.3348 16.0946 15.8093 16.7398C15.8093 16.7398 14.229 17.4082 12.5004 17.4082C12.5004 17.4082 10.7717 17.4082 9.19148 16.7398C9.19148 16.7398 7.66591 16.0946 6.48996 14.9186C6.48996 14.9186 5.31401 13.7427 4.66875 12.2171C4.66875 12.2171 4.00037 10.6369 4.00037 8.9082C4.00037 8.9082 4.00037 7.17955 4.66875 5.59931C4.66875 5.59931 5.31401 4.07374 6.48996 2.8978C6.48996 2.8978 7.66591 1.72185 9.19148 1.07659C9.19148 1.07659 10.7717 0.408203 12.5004 0.408203ZM12.5004 2.4082C12.5004 2.4082 9.80798 2.4082 7.90417 4.31201C7.90417 4.31201 6.00037 6.21582 6.00037 8.9082C6.00037 8.9082 6.00037 11.6006 7.90417 13.5044C7.90417 13.5044 9.80798 15.4082 12.5004 15.4082C12.5004 15.4082 15.1928 15.4082 17.0966 13.5044C17.0966 13.5044 19.0004 11.6006 19.0004 8.9082C19.0004 8.9082 19.0004 6.21582 17.0966 4.31201C17.0966 4.31201 15.1928 2.4082 12.5004 2.4082Z" fill="#1C1C1C" />
                        <path d="M17.9947 18.6249C20.6067 19.8429 22.4591 22.0507 22.4591 22.0507C22.6493 22.2774 22.9298 22.4082 23.2254 22.4082L23.2415 22.408C23.4711 22.4043 23.6923 22.3218 23.8681 22.1742C24.0946 21.9842 24.2254 21.7038 24.2254 21.4082L24.2253 21.392C24.2216 21.1625 24.139 20.9412 23.9915 20.7654L23.9906 20.7643C21.8533 18.2175 18.84 16.8123 18.84 16.8123C15.826 15.4067 12.5004 15.4067 12.5004 15.4067C9.1748 15.4067 6.16083 16.8123 6.16083 16.8123C3.14747 18.2175 1.01019 20.7644 1.01019 20.7644L1.00932 20.7654C0.858215 20.9455 0.775391 21.1731 0.775391 21.4082L0.775395 21.4111C0.775478 21.4392 0.776747 21.4673 0.7792 21.4954C0.802326 21.7596 0.92946 22.0038 1.13264 22.1742C1.31273 22.3253 1.5403 22.4082 1.77539 22.4082C1.8045 22.4082 1.83359 22.4069 1.86259 22.4044C2.1268 22.3812 2.37099 22.2541 2.54146 22.0509C4.39399 19.843 7.00611 18.6249 7.00611 18.6249C9.61822 17.4067 12.5004 17.4067 12.5004 17.4067C15.3826 17.4067 17.9947 18.6249 17.9947 18.6249Z" fill="#1C1C1C" />
                      </svg>

                    </label>
                    <label>
                      <input style={{ marginLeft: "55px" }}
                        placeholder="write your goal" />
                      <svg style={{ marginLeft: "220px" }}
                        width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.0004 14.4082H30.0004C30.5527 14.4082 31.0004 13.9605 31.0004 13.4082C31.0004 12.8559 30.5527 12.4082 30.0004 12.4082H24.0004C23.4481 12.4082 23.0004 12.8559 23.0004 13.4082C23.0004 13.9605 23.4481 14.4082 24.0004 14.4082Z" fill="#1C1C1C" />
                        <path d="M26.0004 10.4082V16.4082C26.0004 16.9605 26.4481 17.4082 27.0004 17.4082C27.5527 17.4082 28.0004 16.9605 28.0004 16.4082V10.4082C28.0004 9.85592 27.5527 9.4082 27.0004 9.4082C26.4481 9.4082 26.0004 9.85592 26.0004 10.4082Z" fill="#1C1C1C" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5004 0.408203C12.5004 0.408203 14.229 0.408203 15.8093 1.07659C15.8093 1.07659 17.3348 1.72185 18.5108 2.8978C18.5108 2.8978 19.6867 4.07374 20.332 5.59931C20.332 5.59931 21.0004 7.17955 21.0004 8.9082C21.0004 8.9082 21.0004 10.6369 20.332 12.2171C20.332 12.2171 19.6867 13.7427 18.5108 14.9186C18.5108 14.9186 17.3348 16.0946 15.8093 16.7398C15.8093 16.7398 14.229 17.4082 12.5004 17.4082C12.5004 17.4082 10.7717 17.4082 9.19148 16.7398C9.19148 16.7398 7.66591 16.0946 6.48996 14.9186C6.48996 14.9186 5.31401 13.7427 4.66875 12.2171C4.66875 12.2171 4.00037 10.6369 4.00037 8.9082C4.00037 8.9082 4.00037 7.17955 4.66875 5.59931C4.66875 5.59931 5.31401 4.07374 6.48996 2.8978C6.48996 2.8978 7.66591 1.72185 9.19148 1.07659C9.19148 1.07659 10.7717 0.408203 12.5004 0.408203ZM12.5004 2.4082C12.5004 2.4082 9.80798 2.4082 7.90417 4.31201C7.90417 4.31201 6.00037 6.21582 6.00037 8.9082C6.00037 8.9082 6.00037 11.6006 7.90417 13.5044C7.90417 13.5044 9.80798 15.4082 12.5004 15.4082C12.5004 15.4082 15.1928 15.4082 17.0966 13.5044C17.0966 13.5044 19.0004 11.6006 19.0004 8.9082C19.0004 8.9082 19.0004 6.21582 17.0966 4.31201C17.0966 4.31201 15.1928 2.4082 12.5004 2.4082Z" fill="#1C1C1C" />
                        <path d="M17.9947 18.6249C20.6067 19.8429 22.4591 22.0507 22.4591 22.0507C22.6493 22.2774 22.9298 22.4082 23.2254 22.4082L23.2415 22.408C23.4711 22.4043 23.6923 22.3218 23.8681 22.1742C24.0946 21.9842 24.2254 21.7038 24.2254 21.4082L24.2253 21.392C24.2216 21.1625 24.139 20.9412 23.9915 20.7654L23.9906 20.7643C21.8533 18.2175 18.84 16.8123 18.84 16.8123C15.826 15.4067 12.5004 15.4067 12.5004 15.4067C9.1748 15.4067 6.16083 16.8123 6.16083 16.8123C3.14747 18.2175 1.01019 20.7644 1.01019 20.7644L1.00932 20.7654C0.858215 20.9455 0.775391 21.1731 0.775391 21.4082L0.775395 21.4111C0.775478 21.4392 0.776747 21.4673 0.7792 21.4954C0.802326 21.7596 0.92946 22.0038 1.13264 22.1742C1.31273 22.3253 1.5403 22.4082 1.77539 22.4082C1.8045 22.4082 1.83359 22.4069 1.86259 22.4044C2.1268 22.3812 2.37099 22.2541 2.54146 22.0509C4.39399 19.843 7.00611 18.6249 7.00611 18.6249C9.61822 17.4067 12.5004 17.4067 12.5004 17.4067C15.3826 17.4067 17.9947 18.6249 17.9947 18.6249Z" fill="#1C1C1C" />
                      </svg>

                    </label>
                  </div>
                </div>
                <div className="thirdFrame">
                  <div className="gratidudediv">
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIsseChecked(!isSEChecked);
                        }}
                      />
                      <svg
                        className={`scheckbox ${isSEChecked ? "scheckbox--active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>

                    </label>
                    <span className="stxt" style={{ marginTop: "3px" }}>Affirmations/Visualizations</span>

                  </div>

                </div>
                <div className="thirdFrame">
                  <div className="gratidudediv">
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIseiChecked(!isEIChecked);
                        }}
                      />
                      <svg
                        className={`scheckbox ${isEIChecked ? "scheckbox--active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>

                    </label>
                    <span className="stxt" style={{ marginTop: "3px" }}>Positive Readings</span>

                  </div>

                </div>
                <div className="secondFrame">

                  <div className="gratidudediv">
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIsniChecked(!isNIChecked);
                        }}
                      />
                      <svg
                        className={`scheckbox ${isNIChecked ? "scheckbox--active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>

                    </label>
                    <span className="stxt" style={{ marginTop: "2px" }}>Two Personal Notes</span>

                  </div>
                  <div className="inputtxt">
                    <label>
                      <input style={{ marginLeft: "55px" }}
                        placeholder="write your goal" />
                      <svg style={{ marginLeft: "220px" }}
                        width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.0004 14.4082H30.0004C30.5527 14.4082 31.0004 13.9605 31.0004 13.4082C31.0004 12.8559 30.5527 12.4082 30.0004 12.4082H24.0004C23.4481 12.4082 23.0004 12.8559 23.0004 13.4082C23.0004 13.9605 23.4481 14.4082 24.0004 14.4082Z" fill="#1C1C1C" />
                        <path d="M26.0004 10.4082V16.4082C26.0004 16.9605 26.4481 17.4082 27.0004 17.4082C27.5527 17.4082 28.0004 16.9605 28.0004 16.4082V10.4082C28.0004 9.85592 27.5527 9.4082 27.0004 9.4082C26.4481 9.4082 26.0004 9.85592 26.0004 10.4082Z" fill="#1C1C1C" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5004 0.408203C12.5004 0.408203 14.229 0.408203 15.8093 1.07659C15.8093 1.07659 17.3348 1.72185 18.5108 2.8978C18.5108 2.8978 19.6867 4.07374 20.332 5.59931C20.332 5.59931 21.0004 7.17955 21.0004 8.9082C21.0004 8.9082 21.0004 10.6369 20.332 12.2171C20.332 12.2171 19.6867 13.7427 18.5108 14.9186C18.5108 14.9186 17.3348 16.0946 15.8093 16.7398C15.8093 16.7398 14.229 17.4082 12.5004 17.4082C12.5004 17.4082 10.7717 17.4082 9.19148 16.7398C9.19148 16.7398 7.66591 16.0946 6.48996 14.9186C6.48996 14.9186 5.31401 13.7427 4.66875 12.2171C4.66875 12.2171 4.00037 10.6369 4.00037 8.9082C4.00037 8.9082 4.00037 7.17955 4.66875 5.59931C4.66875 5.59931 5.31401 4.07374 6.48996 2.8978C6.48996 2.8978 7.66591 1.72185 9.19148 1.07659C9.19148 1.07659 10.7717 0.408203 12.5004 0.408203ZM12.5004 2.4082C12.5004 2.4082 9.80798 2.4082 7.90417 4.31201C7.90417 4.31201 6.00037 6.21582 6.00037 8.9082C6.00037 8.9082 6.00037 11.6006 7.90417 13.5044C7.90417 13.5044 9.80798 15.4082 12.5004 15.4082C12.5004 15.4082 15.1928 15.4082 17.0966 13.5044C17.0966 13.5044 19.0004 11.6006 19.0004 8.9082C19.0004 8.9082 19.0004 6.21582 17.0966 4.31201C17.0966 4.31201 15.1928 2.4082 12.5004 2.4082Z" fill="#1C1C1C" />
                        <path d="M17.9947 18.6249C20.6067 19.8429 22.4591 22.0507 22.4591 22.0507C22.6493 22.2774 22.9298 22.4082 23.2254 22.4082L23.2415 22.408C23.4711 22.4043 23.6923 22.3218 23.8681 22.1742C24.0946 21.9842 24.2254 21.7038 24.2254 21.4082L24.2253 21.392C24.2216 21.1625 24.139 20.9412 23.9915 20.7654L23.9906 20.7643C21.8533 18.2175 18.84 16.8123 18.84 16.8123C15.826 15.4067 12.5004 15.4067 12.5004 15.4067C9.1748 15.4067 6.16083 16.8123 6.16083 16.8123C3.14747 18.2175 1.01019 20.7644 1.01019 20.7644L1.00932 20.7654C0.858215 20.9455 0.775391 21.1731 0.775391 21.4082L0.775395 21.4111C0.775478 21.4392 0.776747 21.4673 0.7792 21.4954C0.802326 21.7596 0.92946 22.0038 1.13264 22.1742C1.31273 22.3253 1.5403 22.4082 1.77539 22.4082C1.8045 22.4082 1.83359 22.4069 1.86259 22.4044C2.1268 22.3812 2.37099 22.2541 2.54146 22.0509C4.39399 19.843 7.00611 18.6249 7.00611 18.6249C9.61822 17.4067 12.5004 17.4067 12.5004 17.4067C15.3826 17.4067 17.9947 18.6249 17.9947 18.6249Z" fill="#1C1C1C" />
                      </svg>

                    </label>
                    <label>
                      <input style={{ marginLeft: "55px" }}
                        placeholder="write your goal" />
                      <svg style={{ marginLeft: "220px" }}
                        width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.0004 14.4082H30.0004C30.5527 14.4082 31.0004 13.9605 31.0004 13.4082C31.0004 12.8559 30.5527 12.4082 30.0004 12.4082H24.0004C23.4481 12.4082 23.0004 12.8559 23.0004 13.4082C23.0004 13.9605 23.4481 14.4082 24.0004 14.4082Z" fill="#1C1C1C" />
                        <path d="M26.0004 10.4082V16.4082C26.0004 16.9605 26.4481 17.4082 27.0004 17.4082C27.5527 17.4082 28.0004 16.9605 28.0004 16.4082V10.4082C28.0004 9.85592 27.5527 9.4082 27.0004 9.4082C26.4481 9.4082 26.0004 9.85592 26.0004 10.4082Z" fill="#1C1C1C" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5004 0.408203C12.5004 0.408203 14.229 0.408203 15.8093 1.07659C15.8093 1.07659 17.3348 1.72185 18.5108 2.8978C18.5108 2.8978 19.6867 4.07374 20.332 5.59931C20.332 5.59931 21.0004 7.17955 21.0004 8.9082C21.0004 8.9082 21.0004 10.6369 20.332 12.2171C20.332 12.2171 19.6867 13.7427 18.5108 14.9186C18.5108 14.9186 17.3348 16.0946 15.8093 16.7398C15.8093 16.7398 14.229 17.4082 12.5004 17.4082C12.5004 17.4082 10.7717 17.4082 9.19148 16.7398C9.19148 16.7398 7.66591 16.0946 6.48996 14.9186C6.48996 14.9186 5.31401 13.7427 4.66875 12.2171C4.66875 12.2171 4.00037 10.6369 4.00037 8.9082C4.00037 8.9082 4.00037 7.17955 4.66875 5.59931C4.66875 5.59931 5.31401 4.07374 6.48996 2.8978C6.48996 2.8978 7.66591 1.72185 9.19148 1.07659C9.19148 1.07659 10.7717 0.408203 12.5004 0.408203ZM12.5004 2.4082C12.5004 2.4082 9.80798 2.4082 7.90417 4.31201C7.90417 4.31201 6.00037 6.21582 6.00037 8.9082C6.00037 8.9082 6.00037 11.6006 7.90417 13.5044C7.90417 13.5044 9.80798 15.4082 12.5004 15.4082C12.5004 15.4082 15.1928 15.4082 17.0966 13.5044C17.0966 13.5044 19.0004 11.6006 19.0004 8.9082C19.0004 8.9082 19.0004 6.21582 17.0966 4.31201C17.0966 4.31201 15.1928 2.4082 12.5004 2.4082Z" fill="#1C1C1C" />
                        <path d="M17.9947 18.6249C20.6067 19.8429 22.4591 22.0507 22.4591 22.0507C22.6493 22.2774 22.9298 22.4082 23.2254 22.4082L23.2415 22.408C23.4711 22.4043 23.6923 22.3218 23.8681 22.1742C24.0946 21.9842 24.2254 21.7038 24.2254 21.4082L24.2253 21.392C24.2216 21.1625 24.139 20.9412 23.9915 20.7654L23.9906 20.7643C21.8533 18.2175 18.84 16.8123 18.84 16.8123C15.826 15.4067 12.5004 15.4067 12.5004 15.4067C9.1748 15.4067 6.16083 16.8123 6.16083 16.8123C3.14747 18.2175 1.01019 20.7644 1.01019 20.7644L1.00932 20.7654C0.858215 20.9455 0.775391 21.1731 0.775391 21.4082L0.775395 21.4111C0.775478 21.4392 0.776747 21.4673 0.7792 21.4954C0.802326 21.7596 0.92946 22.0038 1.13264 22.1742C1.31273 22.3253 1.5403 22.4082 1.77539 22.4082C1.8045 22.4082 1.83359 22.4069 1.86259 22.4044C2.1268 22.3812 2.37099 22.2541 2.54146 22.0509C4.39399 19.843 7.00611 18.6249 7.00611 18.6249C9.61822 17.4067 12.5004 17.4067 12.5004 17.4067C15.3826 17.4067 17.9947 18.6249 17.9947 18.6249Z" fill="#1C1C1C" />
                      </svg>

                    </label>
                    <label>
                      <input style={{ marginLeft: "55px" }}
                        placeholder="write your goal" />
                      <svg style={{ marginLeft: "220px" }}
                        width="31" height="23" viewBox="0 0 31 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.0004 14.4082H30.0004C30.5527 14.4082 31.0004 13.9605 31.0004 13.4082C31.0004 12.8559 30.5527 12.4082 30.0004 12.4082H24.0004C23.4481 12.4082 23.0004 12.8559 23.0004 13.4082C23.0004 13.9605 23.4481 14.4082 24.0004 14.4082Z" fill="#1C1C1C" />
                        <path d="M26.0004 10.4082V16.4082C26.0004 16.9605 26.4481 17.4082 27.0004 17.4082C27.5527 17.4082 28.0004 16.9605 28.0004 16.4082V10.4082C28.0004 9.85592 27.5527 9.4082 27.0004 9.4082C26.4481 9.4082 26.0004 9.85592 26.0004 10.4082Z" fill="#1C1C1C" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5004 0.408203C12.5004 0.408203 14.229 0.408203 15.8093 1.07659C15.8093 1.07659 17.3348 1.72185 18.5108 2.8978C18.5108 2.8978 19.6867 4.07374 20.332 5.59931C20.332 5.59931 21.0004 7.17955 21.0004 8.9082C21.0004 8.9082 21.0004 10.6369 20.332 12.2171C20.332 12.2171 19.6867 13.7427 18.5108 14.9186C18.5108 14.9186 17.3348 16.0946 15.8093 16.7398C15.8093 16.7398 14.229 17.4082 12.5004 17.4082C12.5004 17.4082 10.7717 17.4082 9.19148 16.7398C9.19148 16.7398 7.66591 16.0946 6.48996 14.9186C6.48996 14.9186 5.31401 13.7427 4.66875 12.2171C4.66875 12.2171 4.00037 10.6369 4.00037 8.9082C4.00037 8.9082 4.00037 7.17955 4.66875 5.59931C4.66875 5.59931 5.31401 4.07374 6.48996 2.8978C6.48996 2.8978 7.66591 1.72185 9.19148 1.07659C9.19148 1.07659 10.7717 0.408203 12.5004 0.408203ZM12.5004 2.4082C12.5004 2.4082 9.80798 2.4082 7.90417 4.31201C7.90417 4.31201 6.00037 6.21582 6.00037 8.9082C6.00037 8.9082 6.00037 11.6006 7.90417 13.5044C7.90417 13.5044 9.80798 15.4082 12.5004 15.4082C12.5004 15.4082 15.1928 15.4082 17.0966 13.5044C17.0966 13.5044 19.0004 11.6006 19.0004 8.9082C19.0004 8.9082 19.0004 6.21582 17.0966 4.31201C17.0966 4.31201 15.1928 2.4082 12.5004 2.4082Z" fill="#1C1C1C" />
                        <path d="M17.9947 18.6249C20.6067 19.8429 22.4591 22.0507 22.4591 22.0507C22.6493 22.2774 22.9298 22.4082 23.2254 22.4082L23.2415 22.408C23.4711 22.4043 23.6923 22.3218 23.8681 22.1742C24.0946 21.9842 24.2254 21.7038 24.2254 21.4082L24.2253 21.392C24.2216 21.1625 24.139 20.9412 23.9915 20.7654L23.9906 20.7643C21.8533 18.2175 18.84 16.8123 18.84 16.8123C15.826 15.4067 12.5004 15.4067 12.5004 15.4067C9.1748 15.4067 6.16083 16.8123 6.16083 16.8123C3.14747 18.2175 1.01019 20.7644 1.01019 20.7644L1.00932 20.7654C0.858215 20.9455 0.775391 21.1731 0.775391 21.4082L0.775395 21.4111C0.775478 21.4392 0.776747 21.4673 0.7792 21.4954C0.802326 21.7596 0.92946 22.0038 1.13264 22.1742C1.31273 22.3253 1.5403 22.4082 1.77539 22.4082C1.8045 22.4082 1.83359 22.4069 1.86259 22.4044C2.1268 22.3812 2.37099 22.2541 2.54146 22.0509C4.39399 19.843 7.00611 18.6249 7.00611 18.6249C9.61822 17.4067 12.5004 17.4067 12.5004 17.4067C15.3826 17.4067 17.9947 18.6249 17.9947 18.6249Z" fill="#1C1C1C" />
                      </svg>

                    </label>
                  </div>
                </div>
                <div className="thirdFrame">
                  <div className="gratidudediv">
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIsteChecked(!isTEChecked);
                        }}
                      />
                      <svg
                        className={`scheckbox ${isTEChecked ? "scheckbox--active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>

                    </label>
                    <span className="stxt" style={{ marginTop: "3px" }}>Focus on my hot list daily</span>

                  </div>

                </div>
                <div className="thirdFrame">
                  <div className="gratidudediv">
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIselChecked(!isELChecked);
                        }}
                      />
                      <svg
                        className={`scheckbox ${isELChecked ? "scheckbox--active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>

                    </label>
                    <span className="stxt" style={{ marginTop: "3px" }}>Foucs on my warm list daily</span>

                  </div>

                </div>
                <div className="thirdFrame">
                  <div className="gratidudediv">
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIstwChecked(!isTWChecked);
                        }}
                      />
                      <svg
                        className={`scheckbox ${isTWChecked ? "scheckbox--active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>

                    </label>
                    <span className="stxt" style={{ marginTop: "3px" }}>Exercise</span>

                  </div>

                </div>
              </div>

            </div>
            {/* <div className="center">
        

        </div> */}
            <div className="right">
              <div className="frames">
                <div className="weekanalysis">
                  <span className="frametxt">This Week Analysis</span>
                  <div className="Calendarcol" >
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIsthChecked(!isTHChecked);
                        }}
                      />
                      <svg
                        className={`checkboxCal ${isTHChecked ? "checkboxCal-active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"
                        />
                      </svg>
                      <span className="textCal"> Monday</span>
                    </label>

                    <label >
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIsfoChecked(!isFOChecked);
                        }}
                      />
                      <svg
                        className={`checkboxCal ${isFOChecked ? "checkboxCal-active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>
                      <span className="textCal" > Tuesday</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIsfifChecked(!isFIFChecked);
                        }}
                      />
                      <svg
                        className={`checkboxCal ${isFIFChecked ? "checkboxCal-active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>
                      <span className="textCal"> Wednesday</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIssixChecked(!isSIXChecked);
                        }}
                      />
                      <svg
                        className={`checkboxCal ${isSIXChecked ? "checkboxCal-active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>
                      <span className="textCal"> Thursday</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setIssevChecked(!isSEVChecked);
                        }}
                      />
                      <svg
                        className={`checkboxCal ${isSEVChecked ? "checkboxCal-active" : ""}`}
                        // This element is purely decorative so
                        // we hide it for screen readers
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke="#fff"

                        />
                      </svg>
                      <span className="textCal"> Friday</span>
                    </label>
                  </div>
                </div>
                <div className="weekanalysis">
                  <span className="frametxt" style={{ marginTop: "10px" }}>This Week FORD's Conversations</span>
                  <img src={PieChart} alt="piechart" />
                </div>
                <div className="weekanalysis">
                  <span className="frametxt" >Annual Income Goal</span>
                  <img src={Ellipse} alt="piechart" />
                </div>
              </div>
              <div className="thinginweek">
                <span className="thisweektxt">My one thing this week</span>
                <input style={{ width: "150px" }}
                  placeholder="write down here" />
              </div>
              <div className="realestatereview">
                <span className="thisweektxt">My one thing this week</span>

                <div className="subcontainer">

                  <div className="subwrapper">

                    <div className="subleft">
                      <input style={{ width: "150px" }}
                        placeholder="write down here" />
                    </div>
                    <div className="subcenter">
                      <Calendar fontSize="small" marginTop="20px" />
                    </div>
  
                    <div className="subright">
                        <Testing />
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="realestatereview">    
                     
              </div>
             
            </div>

          </div>
        </div>
      </div>


    </div>
  );
}

export default Dashdata;