import React from 'react'
import "./home.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import List from '../list/List'
import Liveflow from '../liveflow/Liveflow'

const Home = () => {
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
    <Navbar />
    <List/> 
    </div>
    </div>
  )
}

export default Home