import React from 'react'
import "./liveflow.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Lfdatatable from '../../components/liveflowdatatable/Lfdatatable'

const Liveflow = () => {
  return (
    <div className="home">
    <Sidebar />
    <div className="homeContainer">
    <Navbar />
    <Lfdatatable />
    </div>
    </div>
  )
}

export default Liveflow