import Home from "./pages/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import List from "./pages/list/List";
import Liveflow from "./pages/liveflow/Liveflow";
import Dashboard from "./pages/dashboard/Dashboard";
import Testing from "./pages/test/Testing";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

        <Route path="/">
            <Route index element={<Dashboard />} />
            </Route>

          <Route path="Home">
            <Route index element={<Home />} />
            <Route index element={<List />} />
            </Route>

            <Route path="Liveflow">
            <Route index element={<Liveflow />} />
            </Route>

            
          
            
            </Routes>

            </BrowserRouter>

    </div>
  );
}

export default App;
