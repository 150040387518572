import "./sidebar.scss";
import { motion } from "framer-motion";
import {
  AccountCircleRounded,
  AssignmentTurnedInRounded,
  AttachMoneyRounded,
  BarChartRounded,
  ColorLensRounded,
  DashboardRounded,
  SettingsRemoteRounded,
  TocRounded,
} from "@mui/icons-material";

import Item from "../item/Item";
import { useState } from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.svg"
import House from "../../assets/House.png"
import Stack from "../../assets/Stack-d.png"
import Notebook from "../../assets/Notebook-d.png"
import Notepad from "../../assets/Notepad-d.png"
import User from "../../assets/UsersFour-d.png"
import Users from "../../assets/Users-d.png"
import Calendar from "../../assets/CalendarBlank.png"
import Chart from "../../assets/ChartPieSlice-d.png"
import Setting from "../../assets/Gear-d.png"
import Fr from "../../assets/frame.png"
import Plus from "../../assets/Plus.png"

import { Horse, Heart, Cube } from "@phosphor-icons/react";


const Sidebar =()=>  {
//   const { dispatch } = useContext(DarkModeContext);
  const [open, setOpen] = useState(true);

  // for collpsing sidebar
  const handleToggle = () => {
    setOpen(!open);
  };


  return (
    <div className="sidebar">
    <div className="top">
      <Link to="/" style={{ textDecoration: "none" }}>
        <img className="logo" src={Logo} />
      </Link>
    </div>
    <hr />
    <div className="center">
      <button style={{marginTop:"48px",marginLeft:"40px", marginRight:"16px"}} className="btn"><img src={Plus} style={{height:"18px",width:"18px",color:"1c1c1c"}} /><span style={{fontStyle:"bold"}}>New</span></button>
{/*   
      <ul>
        <p className="customtxt">Dashboards</p>
        <li>
          <img  src={House} className="icon"/>
          <span className="menutxt " style={{color: "#1C1C1C"}} >Home</span>
        </li>
        <Link  style={{ textDecoration: "none" }}>
          <li>
            <img src={Stack} className="icon" />
            <span className="menutxt " style={{color: "#1C1C1C"}}>Business Tracker</span>
          </li>
        </Link>
        <Link to="/Home" style={{ textDecoration: "none" }}>
          <li>
            <img src={User} className="icon" />
            <span className="menutxt" style={{color: "#1C1C1C"}}>My People</span>
          </li>
        </Link>
        <Link  style={{ textDecoration: "none" }}>
          <li>
            <img src={Notepad} className="icon" />
            <span className="menutxt" style={{color: "#1C1C1C"}}>Tasks</span>
          </li>
        </Link>
        <Link to="/Liveflow" style={{ textDecoration: "none" }}>
          <li>
            <img src={Notebook} className="icon" />
            <span className="menutxt" style={{color: "#1C1C1C"}}>Live Flow</span>
          </li>
        </Link>
        <Link  style={{ textDecoration: "none" }}>
          <li>
            <img src={Calendar} className="icon" />
            <span className="menutxt" style={{color: "#1C1C1C"}}>Calendar</span>
          </li>
        </Link>
        <Link  style={{ textDecoration: "none" }}>
          <li>
            <img src={Chart} className="icon" />
            <span className="menutxt" style={{color: "#1C1C1C"}}>Goals/Financials</span>
          </li>
        </Link>
        <Link  style={{ textDecoration: "none" }}>
          <li>
            <img src={Users} className="icon" />
            <span className="menutxt" style={{color: "#1C1C1C"}}>Referrals</span>
          </li>
        </Link>
        <p className="customtxt">Account</p>
   
        <Link  style={{ textDecoration: "none" }}>
          <li>
            <img src={Setting} className="icon" />
            <span className="menutxt" style={{color: "#1C1C1C"}}>Setting</span>
          </li>
        </Link>     
      </ul> */}
           <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboards</h3>
          <ul className="sidebarList" style={{marginTop:"20px"}}>
            <Link to="/" className="link">
            <li className="sidebarListItem active ">
            <img  src={House} className="sidebarIcon" />
           Home
            </li>
            </Link>
            <Link className="link">
            <li className="sidebarListItem ">
            <img src={Stack} className="sidebarIcon" />
              Business Tracker
            </li>
            </Link>
            <Link to="/Home" className="link">
            <li className="sidebarListItem ">
            <img src={User} className="sidebarIcon" />
              My People
            </li>
            </Link>
            <Link className="link">
            <li className="sidebarListItem ">
            <img src={Notepad} className="sidebarIcon" />
              Tasks
            </li>
            </Link>
            <Link to="/Liveflow" className="link">
            <li className="sidebarListItem  ">
            <img src={Notebook} className="sidebarIcon" />
             Live Flow
            </li>
            </Link>
            <Link   className="link">
            <li className="sidebarListItem  ">
            <img src={Calendar} className="sidebarIcon" />
             Calendar
            </li>
            </Link>
            <Link  className="link">
            <li className="sidebarListItem ">
            <img src={Chart} className="sidebarIcon" />
             Goals/Financials
            </li>
            </Link>
            <Link  className="link">
            <li className="sidebarListItem ">
            <img src={Users} className="sidebarIcon" />
            Referrals
            </li>
            </Link>
          </ul>
          <h3 className="sidebarTitle">Account</h3>
          <ul className="sidebarList">
            <Link  className="link">
            <li className="sidebarListItem">
            <img src={Setting} className="sidebarIcon" />
          <span > Settings</span>
            </li>
            </Link>
            </ul>
        </div>
        </div>
    <div className="frame">
      <img src={Fr} style={{height:"100px" , width:"200px" }}/>
      <span style={{width:"200px"}}>"If you work the system, the system will work for you."</span>
      <span style={{width:"200px", fontStyle: "italic"}}>Larry Kendall</span>

    </div>
  
  </div>
  </div>

    
 
  );
}
 


export default Sidebar;
