import React from 'react'
import "./dashboard.scss"
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Dashdata from '../../components/dashdata/Dashdata'

const Dashboard = () => {
  return (
    <div className="dashboard">
    <Sidebar />
    <div className="dashboardContainer">
    <Navbar />
    <Dashdata />
    </div>
    </div>
  )
}

export default Dashboard